const chunk = (arr, size) =>
  Array.from({ length: Math.ceil(arr.length / size) }, (v, i) =>
    arr.slice(i * size, i * size + size)
  )

const getRandom = (articlesArray, numOfWantedArticles) => {
  const randomArticles = new Array(numOfWantedArticles)
  let articlesLength = articlesArray.length
  const uniqueReferenceToTakenIndexes = new Array(articlesLength)
  if (numOfWantedArticles > articlesLength) {
    throw new RangeError('getRandom: more elements taken than available')
  }
  while (numOfWantedArticles--) {
    const randomInt = Math.floor(Math.random() * articlesLength)

    const randomUniqueIndex =
      randomInt in uniqueReferenceToTakenIndexes
        ? uniqueReferenceToTakenIndexes[randomInt]
        : randomInt

    const referenceToCurrentTakenIndex =
      --articlesLength in uniqueReferenceToTakenIndexes
        ? uniqueReferenceToTakenIndexes[articlesLength]
        : articlesLength

    randomArticles[numOfWantedArticles] = articlesArray[randomUniqueIndex]
    uniqueReferenceToTakenIndexes[randomInt] = referenceToCurrentTakenIndex
  }
  return randomArticles
}

const maxValueOrNumber = (arr, num) => arr.length < num ? arr.length : num

const sortLatest = (a, b) =>
  new Date(b.createdAt) - new Date(a.createdAt)

const firstCharCapitalize = (str) => {
  if (!str) return

  return str.split(' ').map(
    word => `${word.charAt(0).toUpperCase()}${word.slice(1)}`
  ).join(' ')
}

const getRandomInt = (max) => {
  return Math.floor(Math.random() * Math.floor(max))
}

const getQueryFields = (array, location = null) => (
  array.map((post) => {
    const {
      frontmatter: {
        title,
        date,
        createdAt,
        banner,
        banner2,
        snippet,
        author,
        description,
        tags,
        category
      },
      fields: {
        slug,
        body2Html,
        resources,
        smallThumbnail,
        thumbnail,
        largeThumbnail
      },
      id,
      html: content
    } = post

    return {
      ...(id && { id }),
      ...(title && { title }),
      ...(category && { category }),
      ...(snippet && { snippet }),
      ...(smallThumbnail && { smallPreviewImage: smallThumbnail }),
      ...(thumbnail && { previewImage: thumbnail }),
      ...(largeThumbnail && { largePreviewImage: largeThumbnail }),
      ...(banner && { banner }),
      ...(banner2 && { banner2 }),
      ...(date && { date }),
      ...(createdAt && { createdAt }),
      ...(content && { content }),
      ...(body2Html && { body2Html }),
      ...(resources && { resources }),
      ...(author && { author }),
      ...(description && { description }),
      ...(slug && { slug }),
      ...(tags && { tags }),
      ...(location && { location })
    }
  })
)
module.exports = {
  getRandom,
  maxValueOrNumber,
  sortLatest,
  firstCharCapitalize,
  chunk,
  getRandomInt,
  getQueryFields
}
