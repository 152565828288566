import React from 'react'
import styled from '@emotion/styled'
import Img from 'gatsby-image'
import { Link } from 'gatsby'

import { below } from '../../../utils/media-query'
import { chunk } from '../../../utils/helpers'
import RuledHeader from '../../common/page_components/RuledHeader'
import { previewHoverEffect } from '../../../styles'

const LatestArticles = ({ articles = [], title }) => (
  <LatestArticlesContainer>
    <RuledHeader>{title}</RuledHeader>
    {chunk(articles, 4).map((articleGroup, index) => (
      <ArticleList key={index}>
        {articleGroup.map((article, idx) => (
          <ArticlePreview to={article.slug} key={idx}>
            {article?.previewImage &&
              <ArticleImage
                fluid={article.previewImage.childImageSharp.fluid}
              />}
            <ArticleTitle>{article.title}</ArticleTitle>
          </ArticlePreview>
        ))}
      </ArticleList>
    ))}
  </LatestArticlesContainer>
)

export default LatestArticles

const LatestArticlesContainer = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 75.375rem;
  width: 100%;
  margin: 0 auto;
  padding-left: 3rem;
  padding-right: 3rem;
  padding-top: 50px;
  ${below.med`
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  `}
  ${below.small`
    padding-top: 24px;
    padding-left: 1rem;
    padding-right: 1rem;
  `}
`

const ArticleList = styled.div`
  display: flex;
  flex-direction: row;
  margin: 0 auto;
  width: 100%;
  margin-top: 16px;
  :last-of-type {
    margin-bottom: 50px;
  }
  ${below.small`
    flex-wrap: wrap;
    padding: 0;
    margin: 0 auto;
    :last-of-type {
      margin-bottom: 20px;
    }
  `}
`

const ArticlePreview = styled(Link)`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 16.6875rem;
  height: auto;
  margin: 0 0 0 14px;
  ${previewHoverEffect}
  &:hover {
    cursor: pointer;
    p {
      color: #185f7a !important;
    }
  }
  &:first-child {
    margin 0;
  }
  ${below.med`
    margin-left: 10px;
  `}
  ${below.small`
  min-width: calc(50vw - 20px);
  width: calc(50vw - 20px);
  margin 0;
  margin-bottom: 12px;
  &:nth-child(2n) {
    margin-left: 8px;
  }
    `}
`
const ArticleImage = styled(Img)`
  width: 100%;
  height: 267px;
  ${below.med`
    min-width: 172px;
    width: 172px;
    height: 172px;
  }
  `}
  ${below.small`
    min-width: calc(50vw - 20px);
    width: calc(50vw - 20px);
    height: calc(50vw - 20px);
    min-height: calc(50vw - 20px);
  `}
`

const ArticleTitle = styled.h2`
  margin: 12px 0 0;
  font-family: "Josefin Sans", Helvetica, sans-serif;
  font-size: 20px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.4;
  letter-spacing: normal;
  text-align: center;
  color: #181a1b;
  ${below.med`
    font-size: 16px;
  `}
  ${below.small`
    font-size: 12px;
  `}
`
