import React from 'react'
import styled from '@emotion/styled'
import Img from 'gatsby-image'
import { Link } from 'gatsby'

import { below } from '../../../utils/media-query'
import {
  TwoColumnPanel,
  FeaturedArticleTitle,
  previewHoverEffect
} from '../../../styles'
import FeaturedArticlesList from './FeaturedArticlesList'
import RuledHeader from '../../common/page_components/RuledHeader'

const FeaturedArticles = ({ articles = [], title }) => (
  <FeaturedArticlesContainer>
    <RuledHeader>{title}</RuledHeader>
    <FATwoColumnPanel>
      <MainFeatureArticle to={articles[0]?.slug}>
        <MainFeatureArticleCopy>
          <TitleLines>{articles[0]?.title}</TitleLines>
        </MainFeatureArticleCopy>
        <MainFeatureArticleImage
          fluid={articles[0]?.largePreviewImage.childImageSharp.fluid}
        />
      </MainFeatureArticle>
      <FeaturedArticlesList articles={articles.slice(1, 3)} />
    </FATwoColumnPanel>
  </FeaturedArticlesContainer>
)

const TitleLines = ({ children }) => {
  if (typeof children !== 'string') return null

  return (
    <FAFeaturedArticleTitle as='h2' aria-label={children}>
      {children
        .trim()
        .split(' ')
        .map((lineWord, idx) => (
          <span key={idx}>{lineWord}</span>
        ))}
    </FAFeaturedArticleTitle>
  )
}

const FAFeaturedArticleTitle = styled(FeaturedArticleTitle)`
  font-size: 34px;
  margin-bottom: 8px;
  max-width: 100%;
  margin: 0 !important;
  span{
    padding: 12px 7px 7px;
  }
  ${below.med`
    max-width: 85%;
  `}
  ${below.small`
    max-width: 100%;
    font-size: 19px;
    span{
      padding: 5px 3px;
    }
  `}
`

const FATwoColumnPanel = styled(TwoColumnPanel)`
  padding: 0;
  ${below.med`
    padding-left: 0;
    padding-right: 0;
    flex-wrap: wrap;
  `}
  ${below.small`
    padding: 0;
  `}
`
const FeaturedArticlesContainer = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 75.375rem;
  width: 100%;
  margin: 0 auto;
  padding-left: 3rem;
  padding-right: 3rem;
  padding-top: 50px;
  ${below.med`
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  `}
  ${below.small`
    padding-left: 1rem;
    padding-right: 1rem;
    padding-top: 24px;
  `}
`

const MainFeatureArticle = styled(Link)`
  display: flex;
  width: 50%;
  max-height: 540px;
  margin-right: 15px;
  position: relative;
  ${previewHoverEffect}
  &:hover h2 {
    color: currentColor;
  }
  ${below.med`
    width: 100%;
    margin-right: 0;
    order: 2;
  `}
`
const MainFeatureArticleCopy = styled.div`
  display: flex;
  flex-direction: column;
  position: absolute;
  z-index: 1;
  align-self: flex-end;
  padding-left: 23px;
  margin-bottom: 0px;
  padding-bottom: 37px;
  ${below.med`
    padding-left: 15px;
    padding-bottom: 24px;
  `}
  ${below.small`
    padding-left: 12px;
    padding-bottom: 22px;
  `}
`

const MainFeatureArticleImage = styled(Img)`
  width: 100%;
  min-height: 100%;
  ${below.small`
    height: 338px;
  `}
`

export default FeaturedArticles
