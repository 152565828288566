import React from 'react'
import styled from '@emotion/styled'
import { below } from '../../../utils/media-query'
import AsteriskSvg from '../../../assets/svg/asterisk.inline.svg'
import { firstCharCapitalize } from '../../../utils/helpers'

const TitleDiv = styled.div`
  display: flex;
  align-items: baseline;
  margin-bottom: 27px;
  ${below.med`
    margin-bottom: 18px;
  `}
  ${below.small`
    margin-bottom: 12px;
  `}
`

const Asterisk = styled(AsteriskSvg)`
  width: 22px;
  height: 22px;
  margin-bottom: 0;
  ${below.med`
    width: 15px;
    height: 15px;
  `}
  ${below.small`
    width: 11px;
    height: 14px;
    padding-bottom: 3px;
  `}
`

const CategoryStyleTitle = styled.span`
  font-family: "Abril Fatface", cursive;
  font-size: 52px;
  padding: 0 20px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.23;
  letter-spacing: normal;
  color: #181a1b;
  ${below.med`
    font-size: 38px;
  `}
  ${below.small`
    font-size: 26px;
    padding: 0 11px;
  `}
`

const Underline = styled.div`
  flex-grow: 1;
  border-top: 3px solid #31505c;
  height: 13px;
  ${below.med`
    border-width: 2px;
    height: 11px;
  `}
  ${below.small`
    border-width: 3px;
    height: 7px;
  `}
`

const RuledHeader = ({ children }) => {
  return (
    <TitleDiv>
      <Asterisk />
      <CategoryStyleTitle>{firstCharCapitalize(children)}</CategoryStyleTitle>
      <Underline />
    </TitleDiv>
  )
}

export default RuledHeader
