import React from 'react'
import { Link } from 'gatsby'
import Img from 'gatsby-image'
import styled from '@emotion/styled'
import { below } from '../../../utils/media-query'
import RuledHeader from './RuledHeader'
import DownArrow from '../../../assets/svg/arrow.svg'
import { previewHoverEffect } from '../../../styles'

const VerticalArticlesListContainer = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 75.375rem;
  width: 100%;
  margin: 0 auto;
  padding-left: 3rem;
  padding-right: 3rem;
  padding-top: 50px;
  ${below.med`
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  `}
  ${below.small`
    padding-top: 24px;
    padding-left: 1rem;
    padding-right: 1rem;
  `}
`

const ArticleList = styled.div`
  display: flex;
  flex-direction: column;
`

const ArticleLink = styled(Link)`
  display: flex;
  flex-direction: row;
  margin-bottom: 30px;
  ${previewHoverEffect}
  &:hover h2 {
    color: #181a1b !important;
  }
  &:hover button {
    color: #185f7a !important;
  }
  &:last-of-type {
    margin-bottom: 0;
  }
  ${below.small`
    margin-bottom: 8px;
  `}
`

const ArticlePreviewImage = styled(Img)`
  width: 10.625rem;
  min-width: 10.625rem;
  height: 10.625rem;
  ${below.small`
    width: 96px;
    min-width: 96px;
    height: 96px;
    padding: 0;
  `}
`

const ArticlePreviewCopy = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 24px;
  ${below.small`
    margin-left: 10px;
  `}
`

const ArticlePreviewTitle = styled.h2`
  margin-bottom: 0px;
  font-family: "Josefin Sans", Helvetica, sans-serif;
  font-size: 26px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: normal;
  color: #181a1b;
  ${below.med`
    font-size: 20px;
  `}
  ${below.small`
    margin-right: 0.5rem !important;
    font-size: 12px;
    line-height: 16px;
  `}
`

const ArticlePreviewDescription = styled.p`
  font-family: "Lato", Helvetica, sans-serif;
  font-size: 18px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #181a1b;
  opacity: 0.8;
  margin-top: 0;
  margin-bottom: 0;
  max-width: 730px;
  ${below.med`
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;  
    overflow: hidden;
  `}
  ${below.small`
    font-size: 12px;
    overflow: hidden;
    // width: calc(100vw - ${96 + 10 + 32}px);
    width: 222px;
    height: 54px;
    max-height: 54px;
  `}
`

const ReadMoreButton = styled.button`
  font-family: "Josefin Sans", Helvetica, sans-serif;
  font-size: 12px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: 3.5px;
  color: #31505c;
  margin-top: 24px;
  cursor: pointer;
  margin-top: auto;
  margin-bottom: 7px;
  width: max-content;
  border: none;
  background: transparent;
  padding: 0;
  outline: none;
  width: max-content;
  &::after {
    content: '';
    mask: url(${DownArrow});
    mask-size: cover;
    width: 9px;
    height: 9px;
    transform: rotate(-90deg) translateY(3px);
    display: inline-block;
    background-color: currentColor;
  }
  ${below.med`
    display: none;
  `}
`

const VerticalArticlesList = ({ articles = [], title }) => {
  return (
    <VerticalArticlesListContainer>
      <RuledHeader>{title}</RuledHeader>
      <ArticleList>
        {articles.map((article, idx) => (
          <ArticleLink to={article.slug} key={idx}>
            {article?.smallPreviewImage &&
              <ArticlePreviewImage
                fluid={article.smallPreviewImage.childImageSharp.fluid}
              />}
            <ArticlePreviewCopy>
              <ArticlePreviewTitle>{article.title}</ArticlePreviewTitle>
              <ArticlePreviewDescription>
                {article.snippet.slice(
                  0,
                  article.title.length > 65 ? 180 : 260
                )}
                ...
              </ArticlePreviewDescription>
              <ReadMoreButton expanded={false}>READ MORE </ReadMoreButton>
            </ArticlePreviewCopy>
          </ArticleLink>
        ))}
      </ArticleList>
    </VerticalArticlesListContainer>
  )
}

export default VerticalArticlesList
