import { graphql } from 'gatsby'
import React from 'react'
import PropTypes from 'prop-types'
import { get } from 'lodash'

import CategoryPage from '../components/CategoryPage/CategoryPage'

export default class IndexPage extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      categoryArticles: null
    }
  }

  componentDidMount () {
    this.setState({
      categoryArticles: get(this.props.data, 'allMarkdownRemark.nodes', [])
    })
  }

  render () {
    return this.state.categoryArticles &&
      <CategoryPage posts={this.state.categoryArticles} />
  }
}

IndexPage.propTypes = {
  data: PropTypes.shape({
    allMarkdownRemark: PropTypes.shape({
      edges: PropTypes.array
    })
  })
}

export const pageQuery = graphql`
  query CategoriesQuery($category: String) {
    allMarkdownRemark(
      sort: { order: DESC, fields: [frontmatter___date] }
      filter: {
        frontmatter: {
          templateKey: { eq: "blog-post" }
          category: { eq: $category }
        }
      }
    ) {
      nodes {
        fields {
          slug
          smallThumbnail {
            ...Thumbnail_170
          }
          thumbnail {
            ...Thumbnail_255
          }
          largeThumbnail {
            ...Thumbnail_540
          }
        }
        frontmatter {
          title
          snippet
          banner {
            ...fullWidthBanner
          }
        }
      }
    }
  }
`
