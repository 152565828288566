import React from 'react'

import Layout from '../../components/Layout'

import FeaturedArticles from './page_components/FeaturedArticles'
import VerticalArticleList from '../common/page_components/VerticalArticlesList'
import LatestArticles from './page_components/LatestArticles'
import { sortLatest, getRandom, maxValueOrNumber, getQueryFields } from '../../utils/helpers'
import FeaturedArticleBanner from '../common/page_components/FeaturedArticleBanner'

const CategoryPage = ({ posts }) => {
  const articlesMapped = getQueryFields(posts)
  if (!articlesMapped.length) return null

  const latestArticles = articlesMapped.sort(sortLatest)

  const latestArticle = latestArticles[0]

  const recentArticles = latestArticles.slice(1, 7)
  const articlesAlreadyShown = [latestArticle, ...recentArticles]
  const getArticles = ({ numOfArticles, featured, trending }) => {
    const articlesStillNotShown = articlesMapped.filter(
      (el) => [...articlesAlreadyShown].indexOf(el) < 0
    )
    const articlesNum = articlesMapped.length
    const enoughArticles = articlesNum >= 14
    const enough4Featured = articlesNum >= 10
    const missingArticles = 14 - articlesNum <= 4 ? 14 - articlesNum : 4
    const missing4Featured = 10 - articlesNum <= 3 ? 10 - articlesNum : 3
    const random = (array, number) =>
      getRandom(array, maxValueOrNumber(array, number))
    const articles = random(articlesStillNotShown, numOfArticles)
    const handleMissing = (number) => {
      const notShownIds = articlesStillNotShown.map(({ id }) => id)
      const mappedAndShownArticles =
        articlesMapped.filter(({ id }) => !notShownIds.includes(id))
      return [
        ...random(articlesStillNotShown, numOfArticles),
        ...random(mappedAndShownArticles, number)
      ]
    }
    const result =
      (featured && enough4Featured) || (trending && enoughArticles)
        ? articles
        : featured && !enough4Featured
          ? handleMissing(missing4Featured)
          : handleMissing(missingArticles)

    articlesAlreadyShown.push(...result)
    return result
  }

  return (
    <Layout removeFooter adsense>
      <FeaturedArticleBanner article={latestArticle} />
      <FeaturedArticles
        articles={getArticles({ numOfArticles: 3, featured: true })}
        title='Feature Articles'
      />
      <VerticalArticleList
        articles={recentArticles}
        title='Recently Published Articles'
      />
      <LatestArticles
        articles={getArticles({ numOfArticles: 8, trending: true })}
        title='Trending Topics'
      />
    </Layout>
  )
}

export default CategoryPage
