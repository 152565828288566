import React from 'react'
import styled from '@emotion/styled'
import Img from 'gatsby-image'
import { Link } from 'gatsby'

import { below } from '../../../utils/media-query'
import { previewHoverEffect } from '../../../styles'

const FeaturedArticlesList = ({ articles = [], title }) => (
  <FeaturedArticlesListContainer>
    <VerticalArticlesList>
      {articles.map((article, idx) => (
        <ArticlePreview to={article.slug} key={idx}>
          {article?.previewImage &&
            <ArticlePreviewImage
              fluid={article.previewImage.childImageSharp.fluid}
            />}
          <ArticlePreviewCopy>
            <ArticlePreviewTitle>{article.title}</ArticlePreviewTitle>
            <ArticlePreviewDescription>{article.snippet}...</ArticlePreviewDescription>
          </ArticlePreviewCopy>
        </ArticlePreview>
      ))}
    </VerticalArticlesList>
  </FeaturedArticlesListContainer>
)

export default FeaturedArticlesList

const FeaturedArticlesListContainer = styled.div`
  width: 50%;
  margin-left: 15px;
  ${below.med`
    margin: 0;
    padding-top: 0;
    width: 100%;
  `}
`

const VerticalArticlesList = styled.div`
  display: flex;
  flex-direction: column;
  ${below.med`
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 12px;
    column-gap: 20px;
  `}
  ${below.small`
    column-gap: unset;
  `}
`

const ArticlePreview = styled(Link)`
  display: flex;
  flex-direction: row;
  margin-bottom: 30px;
  ${previewHoverEffect}
  :last-of-type {
    margin-bottom: 0;
  }
  ${below.med`
    margin-bottom: 0;
    flex-direction: column;
    width: calc(50vw - 20px);
  `}
`

const ArticlePreviewCopy = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 24px;
  ${below.med`
    margin-left: 0px;
    padding: 0 10px;
  `}
  ${below.small`
    padding: 0 ;
  `}

`
const ArticlePreviewDescription = styled.p`
  font-family: "Lato", Helvetica, sans-serif;
  font-size: 18px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.56;
  letter-spacing: normal;
  color: #181a1b;
  opacity: 0.8;
  margin-top: 10px;
  margin-bottom: 0;
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;  
  overflow-y: hidden;
  ${below.med`
    display: none;
  `}
`

const ArticlePreviewImage = styled(Img)`
  width: 15.9375rem;
  min-width: 15.9375rem;
  height: 15.9375rem;
  ${below.med`
    width: 100%;
    min-width: 100%;
    height: calc(50vw - 20px);
    padding: 0;
    `}
  ${below.small`
    min-width: calc(50vw - 20px);
  `}
`

const ArticlePreviewTitle = styled.h2`
  margin-bottom: 0px;
  font-family: "Josefin Sans", Helvetica, sans-serif;
  font-size: 20px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: normal;
  color: #181a1b;
  ${below.med`
    font-size: 24px;
    text-align: center;
    margin-top: 10px;
  `}
  ${below.small`
    margin-right: 0.5rem !important;
    font-size: 14px;
    text-align: center;
    font-size: 12px;
    margin-top: 6px;
  `}
`
